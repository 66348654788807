<template>
    <div>
        <v-card>
            <v-container>
                <v-row>
                    <h3 class="mt-3 ml-3"> REPORTES</h3>                        
                </v-row>

				<hr style="margin: 20px 0;">

                <v-row>

                    <v-col lg="6" class="pt-0">
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar style="display: flex; justify-content: center; align-items: center">
                                    <v-btn color="success" fab @click="PrfDistributionPalletTemperatureTunnelReport()"><i class="fas fa-file-excel" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b><a style="color: black"> Reporte de distribución de pallet y medición de temperatura en tunel</a></b></v-list-item-title>
                                </v-list-item-content>
                                
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar style="display: flex; justify-content: center; align-items: center">
                                    <v-btn color="success" fab @click="PrfControlTemperatureChamberProductFinishReport()"><i class="fas fa-file-excel" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b><a style="color: black"> Reporte de control de temperatura en cámara de producto terminado</a></b></v-list-item-title>
                                </v-list-item-content>
                                
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar style="display: flex; justify-content: center; align-items: center">
                                    <v-btn color="success" fab @click="upDialigDistributionTemperaturaTunel()"><i class="fas fa-file-excel" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b><a style="color: black"> Temperatura Tunel </a></b></v-list-item-title>
                                </v-list-item-content>
                                
                            </v-list-item>
                        </v-list>
                    </v-col>

                </v-row>

            </v-container>
        </v-card>

        <v-dialog
            v-model="dialogDistributionTunnelPallet"
            fullscreen
        >
            <s-toolbar
                label="Distribución de pallets en tunel"
                close
                @close="dialogDistributionTunnelPallet = false"
            ></s-toolbar>
            <v-card>
                <v-container>
                    <v-row>
                        <v-col cols="10">
                            <s-date
                                label="Fecha"
                                v-model="filter.DateBegin"
                            ></s-date>
                        </v-col>
                        <v-col cols="2">
                            <v-btn
                                success
                                @click="runBatchTunnelByDay()"
                            >
                                <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-container v-for="(item, index) in palletsInTunel" :key="index">
                        <!--  <v-row v-for="(item, index) in data" :key="index">
                            
                        </v-row> -->
                        <template>
                            <v-simple-table>
                                <thead>
                                    <!--  <tr>
                                        <th rowspan="2">
                                            {{ item.ChaDescription }}
                                        </th>
                                        <th>
                                            <tr>
                                                <th>
                                                    Pallets
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>
                                                    2
                                                </th>
                                            </tr>
                                        </th>
                                    </tr> -->
                                    <tr>
                                        <th v-for="(h, hea) in vheader" :key="hea">
                                            {{h}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(f1, f) in dataBatch.palletstempamb">
                                        <th>{{f1}}</th>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </template>
                    </v-container>
                </v-container>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog"
            width="400"
        >
            <v-card>
                <v-container>
                    <v-row>
                        <s-toolbar
                            download
                            @download="PrfDistributionPalletTemperatureTunnelReportDownload()"
                            label="Descargar Reporte"
                        >

                        </s-toolbar>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <s-select
                                item-value="ChaID"
                                item-text="ChaDescription"
                                :items="tunnels"
                                label="Tuneles"
                                v-model="ChaID"
                            ></s-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        
        <v-dialog
            v-model="dialogCooling"
            width="400"
        >
            <v-card>
                <v-container>
                    <v-row>
                        <s-toolbar
                            download
                            @download="PrfControlTemperatureChamberProductFinishReportDownload()"
                            label="Descargar Reporte"
                        >

                        </s-toolbar>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <s-select
                                item-value="ChaID"
                                item-text="ChaDescription"
                                :items="Coolers"
                                label="Camaras"
                                v-model="ChaID"
                            ></s-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>

    import PrfChamberService from '../../../services/FreshProduction/PrfChamberService';
    import PrfCoolingChamberService from '../../../services/FreshProduction/PrfCoolingChamberService';
    import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";

	export default {
		data() {
			return {
                tunnels: [],
                Coolers: [],
                items: {},
                dialog: false,
                dialogCooling: false,
                ChaID: 0,
                dialogDistributionTunnelPallet: false,
                filter:{},
                report: {},
                palletsInTunel: [],
                dataBatch : [],
                vheader: {
                    npallet1: "N° Pallet",
                    npallet2: "N° Pallet",
                    hora   : "Hora",
                    tamb   : "T. Amb.",
                    tpro1   : "T. Prod. (C°)",
                    tpro2   : "T. Prod. (C°)",
                    tpro3   : "T. Prod. (C°)",
                    tpro4   : "T. Prod. (C°)",
                }
            };
		},

		methods: {

            runBatchTunnelByDay()
            {
                let parametervalue = [];

                parametervalue.push(this.filter.DateBegin);
                let parametergen = "@dateBegin";
                this.getDataBatchTunnelByDay(" BD BRIX - RECEPCION","PRF_RPT_BATCHSTUNNELBYDAY",parametervalue,parametergen);
            },
                
            /* runDataDistributionTunnelPallet(params)
            {
                let parametervalue = [];
                parametervalue.push(params.ChaID);
                parametervalue.push(params.CcbID);
                let parametergen = "@ChaID, @CcbID";
                this.getDataDistributionTunnelPallet(" ","PRF_RPT_DATADISTRIBUTIONTUNELPALLET",parametervalue,parametergen);
            }, */

            getDataDistributionTunnelPallet(params)
            {
                let parametervalue = [];
                parametervalue.push(params.ChaID);
                parametervalue.push(params.CcbID);
                let parametergen = "@ChaID, @CcbID";
                
                let report2 = {
                    QryNameReport : " ",
                    QryParamsArray : parametergen,
                    QryParamsArrayValue : parametervalue.join(","),
                    QryNameProcedure : "PRF_RPT_DATADISTRIBUTIONTUNELPALLET"
                }

                //console.log('parametervalue ', this.report2);
                return new Promise(
                    resolve => {
                        _sQryConfigurationService
                        .querygeneral(report2, this.$fun.getUserID())
                        .then(
                            (res) => {
                                if (res.status == 200) {
                                    resolve(res.data);
                                }
                            }
                        );
                    }
                );
                
            },

            getDataDistributionTunnelPalletTempEnv(params)
            {
                let parametervalue = [];
                parametervalue.push(params.ChaID);
                parametervalue.push(params.CcbID);
                let parametergen = "@ChaID, @CcbID";
                
                let report3 = {
                    QryNameReport : " ",
                    QryParamsArray : parametergen,
                    QryParamsArrayValue : parametervalue.join(","),
                    QryNameProcedure : "PRF_RPT_DATADISTRIBUTIONTUNELPALLET_TEMPENV"
                }

                //console.log('parametervalue ', this.report2);
				return new Promise(
                    resolve => {
                        _sQryConfigurationService
                        .querygeneral(report3, this.$fun.getUserID())
                        .then(
                            (res) => {
                                if (res.status == 200) {
                                    resolve(res.data);
                                }
                            }
                        )
                    }
                )
            },

            getDataDistributionTunnelPalletTempPulpe(params)
            {
                let parametervalue = [];
                parametervalue.push(params.ChaID);
                parametervalue.push(params.CcbID);
                let parametergen = "@ChaID, @CcbID";
                
                let report4 = {
                    QryNameReport : " ",
                    QryParamsArray : parametergen,
                    QryParamsArrayValue : parametervalue.join(","),
                    QryNameProcedure : "PRF_RPT_DATADISTRIBUTIONTUNELPALLET_TEMPPULPE"
                }

                //console.log('parametervalue ', this.report2);
				return new Promise(
                    resolve => {
                        _sQryConfigurationService
                        .querygeneral(report4, this.$fun.getUserID())
                        .then(
                            (res) => {
                                if (res.status == 200) {
                                    resolve(res.data);
                                }
                            }
                        )
                    }
                )
            },

            async getDataBatchTunnelByDay(QryNameReport,qryname,parametervalue,parametergen) {
				this.report.QryNameReport = QryNameReport	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArray = parametergen	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArrayValue = parametervalue.join(","); //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryNameProcedure = qryname;
					//this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;
                
				this.dataBatch = [];
				await _sQryConfigurationService
                .querygeneral(this.report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.palletsInTunel = resp.data;
                            this.palletsInTunel.forEach(async element => {
                                let pallets = await this.getDataDistributionTunnelPallet(element);
                                let palletstempamb = await this.getDataDistributionTunnelPalletTempEnv(element);
                                let palletstemppulpe = await this.getDataDistributionTunnelPalletTempPulpe(element);

                                let obj = {
                                    tunel : element,
                                    pallets : pallets,
                                    palletstempamb : palletstempamb,
                                    palletstemppulpe : palletstemppulpe
                                }
                                this.dataBatch.push(obj);
                            })
                            
                            
                        }
                    }
                );

                console.log('totalllllllllll ', this.dataBatch);

			},  

            upDialigDistributionTemperaturaTunel()
            {
                this.dialogDistributionTunnelPallet = true;
            },

            Initialize()
            {
                PrfChamberService.list(this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200){
                        resp.data.forEach(element => {
                            let content = element.ChaDescription.match("Tunel.*")
                            if(content){
                                this.tunnels.push(element);
                            }else{
                                this.Coolers.push(element);
                            }
                        });
                    }
                })
            },

            PrfDistributionPalletTemperatureTunnelReport()
            {
                
                this.$fun.alert("Seguro de descargar?", "question")
                .then(resp => {
                    if(resp.value){
                        this.dialog = true;
                    }
                })
            },

            PrfDistributionPalletTemperatureTunnelReportDownload()
            {
                PrfCoolingChamberService
                .PrfDistributionPalletTemperatureTunnelReport(this.ChaID, this.$fun.getUserID())
                .then((r) => {
                    this.$fun.downloadFile(
                        r.data,
                        this.$const.TypeFile.PDF,
                        "Distribución de pallets"
                    );
                });
            },

            PrfControlTemperatureChamberProductFinishReport()
            {
                
                this.$fun.alert("Seguro de descargar?", "question")
                .then(resp => {
                    if(resp.value){
                        this.dialogCooling = true;
                    }
                })
            },

            PrfControlTemperatureChamberProductFinishReportDownload()
            {
                PrfCoolingChamberService
                .PrfControlTemperatureChamberProductFinishReport(this.ChaID, this.$fun.getUserID())
                .then((r) => {
                    this.$fun.downloadFile(
                        r.data,
                        this.$const.TypeFile.PDF,
                        "Distribución de pallets"
                    );
                });
            }

		},

        created () {
            this.Initialize();
            
        },
	};
</script>

<style>
</style>